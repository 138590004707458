import React from 'react'
import Layout from '../layout'

const Plans = () => {
  return (
    <Layout>

    </Layout>
  )
}

export default Plans
